import './index.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'

import Container from '../Container'

const bem = new BEMHelper('error-header')

export default function ErrorHeader () {
  return (
    <header {...bem('')}>
      <Container small>
        <h1>Something has gone wrong</h1>

        <p>This page has an error, or does not exist: which it should.</p>

        <p>
          Contact us for more information:
          <br />
          Phone: +47 527 00 140
        </p>

        <p>We're very sorry for the inconvenience</p>
      </Container>
    </header>
  )
}
