import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import ErrorHeader from '../../components/ErrorHeader'

import imageHelper from '../../helpers/image-helper'

export default class PricesSubmit extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.any,
  }

  state = {
    email: '',
  }

  componentDidMount () {
    const email = window.sessionStorage.getItem('email')
    this.setState({ email })
  }

  render () {
    const { email } = this.state
    const { data } = this.props

    if (!data.ievv) {
      return <ErrorHeader />
    }

    const { heroImage } = data.ievv

    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : {}

    return (
      <Hero
        {...hero}
        title="Thank you"
        preamble={`Your request is now in the hands of our sales team, and your quote will follow shortly. In the meantime we sent a copy of your request to "${email}".`}
      />
    )
  }
}

export const query = graphql`
  query {
    ievv(pagetypeid: { eq: "ncl_backend_pageframework.Price" }) {
      ...ArticleData
    }
    ...GetImages
  }
`
